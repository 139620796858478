<template>
  <div class="ui grid container-1">
    <div class="content">
      <h2>BECOME A</h2>
      <h1>PARTNER</h1>
      <p>Earn additional income when you become a <br> TayoCash Partner or Merchant.</p>
      <router-link class="yellow" to="/partner" v-scroll-to="'#srvcof'">
        LEARN MORE
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'content1'
}
</script>

<style scoped lang='scss'></style>
